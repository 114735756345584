html,
body {
  max-width: 100% !important;
  overflow-x: hidden !important;
  overflow-x: hidden;
  width: 100%;
  position: relative;
}

@font-face {
  font-family: "pixel";
  src: local("pixel"), url("./fonts/pixel.woff") format("woff");
}

body {
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
p {
  font-family: "pixel";
}

h1 {
  font-size: 140px;
}

h2 {
  font-size: 26px !important;
}

p {
  font-size: 30px;
}

.node_hover:hover {
  fill: #ff00ff;
  fill-opacity: 1;
  stroke-width: 2px;
  stroke: #ff00ff;
}

.node_hover {
  fill: #ff00ff;
  fill-opacity: 0;
}

.minting:after {
  content: ".";
  animation: dots 2s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  15% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.35em 0 0 rgba(0, 0, 0, 0), 0.7em 0 0 rgba(0, 0, 0, 0);
  }
  25% {
    color: #ffff00;
    text-shadow: 0.35em 0 0 rgba(0, 0, 0, 0), 0.7em 0 0 rgba(0, 0, 0, 0);
  }
  35% {
    text-shadow: 0.35em 0 0 #ffff00, 0.7em 0 0 rgba(0, 0, 0, 0);
  }
  45%,
  65% {
    text-shadow: 0.35em 0 0 #ffff00, 0.7em 0 0 #ffff00;
  }
  95%,
  100% {
    text-shadow: 0.35em 0 0 #ffff00, 0.7em 0 0 #ffff00;
  }
}

.hoverable:hover {
  cursor: pointer;
}

.hoverable {
  cursor: pointer;
  color: #ff00ff;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

.header {
  font-size: 70px !important;
}

.backAdjust {
  bottom: 60px;
}

.fontAdjust {
  font-size: 25px !important;
}

.buttonResize {
  width: 64px;
  height: 64px;
  bottom: 50px;
}

.introText {
  font-size: 18px !important;
  max-width: 330px;
  line-height: 150% !important;
}

.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  left: -55%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  width: 400%;
}

.infoText {
  color: #ffff00 !important;
  font-size: 22px !important;
}

.bio p {
  color: black;
  font-size: 20px;
}

.sectionTopPadding {
  padding-top: 70px;
}

#walleth2 {
  font-size: 23px !important;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  h2 {
    font-size: 30px !important;
  }

  #walleth2 {
    font-size: 30px !important;
  }

  .hoverable:hover {
    color: #ffff00;
    cursor: pointer;
  }

  .hoverable {
    cursor: pointer;
    color: #ff00ff;
  }

  .header {
    font-size: 120px !important;
  }
  .backAdjust {
    bottom: 40px;
  }

  .sectionTopPadding {
    padding-top: 120px;
  }

  .bio p {
    color: black;
    font-size: 26px;
  }

  .fontAdjust {
    opacity: 100;
    font-size: 30px !important;
  }

  .infoText {
    color: #ffff00 !important;
    font-size: 30px !important;
  }

  .buttonResize {
    width: 85px !important;
    height: 85px !important;
    bottom: 20px;
  }

  .introText {
    font-size: 25px !important;
    max-width: 450px;
  }

  .outer {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    left: 0%;
  }

  .inner {
    width: 100%;
  }
}

.font22Px {
  font-size: 22px !important;
}

.vh-80 {
  height: 80vh;
}

.nopadding {
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.bg-red {
  background-color: red !important;
}

.bg-blue {
  background-color: blue !important;
}

.centerThis {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

div {
  white-space: pre-wrap;
}

.main-footer {
  position: sticky;
  bottom: 0;
  border-color: red;
}

.footery p {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.clickable {
  cursor: pointer;
}

.bg-lightish {
  background-color: #ffff9b !important;
}

.allCaps {
  text-transform: uppercase;
}

.blink {
  animation: blink-animation 1s steps(2, start) 2;
  -webkit-animation: blink-animation 1s steps(2, start) 2;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.overlay {
  position: fixed; /* Sit on top of the page content */

  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9); /* Black background with opacity */
  z-index: 20; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
